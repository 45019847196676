<template>
    <VLayout style="overflow: unset">
        <VNavigationDrawer
            v-model="navigation.opened"
            :mobile="breakpoint.lt('t-md')"
            :rail="breakpoint.ge('t-md')"
            :style="{
                ...(breakpoint.ge('t-md') && (navigation.hovered || navigation.submenu) ? {
                    width: '256px',
                } : {}),
            }"
            class="position-fixed tablet:!tw-hidden"
            color="primary-navigation"
            rail-width="80"
            @mouseenter="navigation.hovered = true"
            @mouseleave="navigation.hovered = false"
        >
            <template #prepend>
                <div
                    v-if="breakpoint.ge('t-md')"
                    :class="$style['logo']"
                    aria-label="PeakPicks"
                >
                    <AppLogoSymbol
                        :class="$style['logo__symbol']"
                        aria-hidden="true"
                    />

                    <VFadeTransition>
                        <AppLogoText
                            v-if="navigation.hovered || navigation.submenu || breakpoint.lt('t-md')"
                            :class="$style['logo__text']"
                            aria-hidden="true"
                        />
                    </VFadeTransition>
                </div>

                <div v-else class="px-1 py-4">
                    <VBtn
                        aria-label="Close"
                        color="white"
                        icon="mdi-close"
                        variant="text"
                        rounded="50%"
                        @click="navigation.opened = false"
                    />
                </div>
            </template>

            <VList nav>
                <VListItem
                    :to="{ name: ROUTES.BROWSE }"
                    prepend-icon="mdi-home"
                    title="Browse"
                    class="pl-5"
                    exact
                />

                <VListItem
                    :to="{ name: ROUTES.TOP_10_PICKS }"
                    prepend-icon="mdi-magnify"
                    title="Product Finder"
                    class="pl-5"
                />

                <VListItem
                    :to="{ name: ROUTES.PRODUCT_TRACKER }"
                    prepend-icon="mdi-creation"
                    title="Product Tracker"
                    class="pl-5"
                    exact
                >
                    <VChip
                        class="position-absolute"
                        color="app-orange-2"
                        size="small"
                        style="top: -10px; right: -7px;"
                        text="Free"
                        variant="flat"
                    />
                </VListItem>

                <VListItem
                    :to="{ name: ROUTES.TRENDING_PRODUCTS }"
                    prepend-icon="mdi-trending-up"
                    title="Trending Products"
                    class="pl-5"
                />
            </VList>

            <template #append>
                <VList nav>
                    <VListItem
                        :to="{ name: ROUTES.PRICING }"
                        prepend-icon="mdi-currency-usd"
                        title="Upgrade"
                        class="pl-5"
                        exact
                    />

                    <VListItem
                        :to="{ name: ROUTES.SUPPORT }"
                        prepend-icon="mdi-message-question"
                        title="Contact support"
                        class="pl-5"
                        exact
                    />
                </VList>

                <VDivider color="white" />

                <VList v-if="store.user">
                    <VMenu v-model="navigation.submenu" location="end">
                        <template #activator="{ props }">
                            <VListItem
                                :title="store.user.fullName"
                                :subtitle="store.user.email"
                                v-bind="props"
                                prepend-icon="mdi-account-box"
                                class="pl-7"
                            />
                        </template>

                        <VList class="bg-primary-navigation">
                            <VListItem
                                :to="{ name: ROUTES.SETTINGS }"
                                prepend-icon="mdi-cogs"
                                title="Settings"
                                exact
                            />

                            <VListItem
                                prepend-icon="mdi-exit-run"
                                title="Log out"
                                @click="logout"
                            />
                        </VList>
                    </VMenu>
                </VList>
            </template>
        </VNavigationDrawer>
        <div class="tw-hidden tw-w-[100px] tw-h-screen tw-fixed tw-py-5 tw-px-2.5 tablet:tw-flex tw-flex-col tw-items-center tw-bg-white">
            <img class="tw-mb-5" src="/images/small-logo.svg" alt="PeakPicks Logo" />
            <div class="tw-flex-1">
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.BROWSE }"
                >
                    <div>
                        <GridIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Browse
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.TOP_10_PICKS }"
                >
                    <div>
                        <ProfitFinderIcon class="tw-w-5 group-[.router-link-active]:tw-text-finder" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Product Finder
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.PRODUCT_TRACKER }"
                >
                    <div>
                        <ProductTrackerIcon class="group-[.router-link-active]:tw-text-tracker" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Product Tracker
                    </div>
                    <div class="tw-px-[5px] tw-py-1 tw-rounded tw-text-[#00C060] tw-font-semibold tw-text-[11px] tw-bg-white tw-leading-none">
                        FREE
                    </div>
                </RouterLink>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.TRENDING_PRODUCTS }"
                >
                    <div>
                        <TrendingIcon class="tw-w-5 group-[.router-link-active]:tw-text-tracker" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Trending Products
                    </div>
                    <div class="tw-px-[5px] tw-py-1 tw-rounded tw-text-[#00C060] tw-font-semibold tw-text-[11px] tw-bg-white tw-leading-none">
                        FREE
                    </div>
                </RouterLink>
            </div>
            <div>
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.PRICING }"
                >
                    <div>
                        <RoundArrowIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Upgrade Your Plan
                    </div>
                </RouterLink>
                <hr class="tw-my-1 tw-border-black/20" />
                <RouterLink
                    class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                    active-class="tw-bg-[#e6e6f7] router-link-active tw-pointer-events-none"
                    :to="{ name: ROUTES.SUPPORT }"
                >
                    <div>
                        <SupportIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                    </div>
                    <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                        Support
                    </div>
                </RouterLink>
                <hr class="tw-my-1 tw-border-black/20" />
                <UiDropdownMenu>
                    <UiDropdownMenuTrigger as-child>
                        <div
                            class="tw-group tw-min-h-[80px] tw-py-[9px] tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                        >
                            <div>
                                <ProfileIcon class="group-[.router-link-active]:tw-text-pp-blue" />
                            </div>
                            <div class="tw-text-center tw-text-small group-[.router-link-active]:tw-text-black">
                                Profile
                            </div>
                        </div>
                    </UiDropdownMenuTrigger>
                    <UiDropdownMenuContent class="tw-w-[140px]" align="end" :side-offset="10" side="right">
                        <UiDropdownMenuItem class="tw-flex tw-gap-2.5" @click="router.push({ name: ROUTES.SETTINGS})">
                            Settings
                        </UiDropdownMenuItem>
                        <UiDropdownMenuItem class="tw-flex tw-gap-2.5" @click="logout">
                            Logout
                        </UiDropdownMenuItem>
                    </UiDropdownMenuContent>
                </UiDropdownMenu>
            </div>
        </div>

        <VAppBar
            v-if="breakpoint.lt('t-md')"
            :class="[$style['top'], 'position-fixed']"
            color="primary-navigation"
            height="80"
            style="z-index: 100"
            flat
        >
            <template #prepend>
                <VAppBarNavIcon
                    color="white"
                    @click="navigation.opened = true"
                />
            </template>

            <AppLogo :class="$style['top__logo']" />
        </VAppBar>

        <VMain class="tw-flex tw-justify-center !tw-pt-20 tablet:!tw-pt-0 !tw-pl-0 tablet:!tw-pl-[100px]">
            <div class="pa-4 pa-sm-8 tw-max-w-[1400px] tw-w-full">
                <RouterView />
            </div>
            <SupportWidget />
            <UpgradeWidget />
        </VMain>
    </VLayout>
</template>

<script lang="ts" setup>
import AppLogo from '@/assets/sprites/logo.svg'
import AppLogoSymbol from '@/assets/sprites/logo-symbol.svg'
import AppLogoText from '@/assets/sprites/logo-text.svg'

import { UnauthorizedHttpError } from '@/api/client'

import {
  onErrorCaptured, onMounted,
  reactive,
} from 'vue'

import { useBreakpoint } from '@/composables/breakpoint.ts'
import useMainStore from '@/store/main'

import { ROUTES } from '@/router'
import { useRouter } from 'vue-router'
import GridIcon from '@/kit/icons/GridIcon.vue'
import ProfitFinderIcon from '@/kit/icons/ProfitFinderIcon.vue'
import ProductTrackerIcon from '@/kit/icons/ProductTrackerIcon.vue'
import RoundArrowIcon from '@/kit/icons/RoundArrowIcon.vue'
import SupportIcon from '@/kit/icons/SupportIcon.vue'
import ProfileIcon from '@/kit/icons/ProfileIcon.vue'
import { UiDropdownMenu, UiDropdownMenuContent, UiDropdownMenuItem, UiDropdownMenuTrigger } from '@/kit/dropdown'
import TrendingIcon from '@/kit/icons/TrendingIcon.vue'
import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import SupportWidget from '@/components/SupportWidget.vue'
import UpgradeWidget from '@/components/UpgradeWidget.vue'

const breakpoint = useBreakpoint()
const navigation = reactive({
  hovered: false,
  opened: breakpoint.value.ge('t-md'),
  submenu: false,
})

const store = useMainStore()
const router = useRouter()

const logout = () => {
  shutdown()
  store.set({
    jwt: null,
    user: null,
    isProfitFinderDemoWidgetClosed: false,
    isProductTrackerDemoWidgetClosed: false,
    isTrendingProductsDemoWidgetClosed: false,
  })
  window.dataLayer.push({
    event: 'logout',
    user_id: null,
  })
}

const connectIntercom = ({ userId, username, email, createdAt }: { userId: number | undefined; username: string | undefined; email: string | undefined; createdAt: Date | undefined }) => {
  Intercom({
    app_id: 'kjvvbbkx',
    user_id: userId?.toString(), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: Number(createdAt), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  })
}

onMounted(() => {
  connectIntercom({
    userId: store.user?.id,
    username: store.user?.fullName,
    email: store.user?.email,
    createdAt: store.user?.createdAt,
  })
})

onErrorCaptured((e) => {
  if (e instanceof UnauthorizedHttpError) {
    logout()
  }
})
</script>

<style lang="scss" module>
@use '../stylesheets/basics/colors';

.top {
  :global(.v-toolbar__content) {
    border-bottom: 1px solid rgba(var(--v-theme-on-primary-navigation), var(--v-border-opacity));
  }

  &__logo {
    width: 120px;
    height: 24px;
    color: colors.$green-2;
    margin-right: 16px;
  }
}

.logo {
  display: flex;
  width: 100%;
  padding: 24px;
  color: #DFFF72;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  &__symbol {
    display: inline-block;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  &__text {
    display: inline-block;
    height: 24px;
    flex-shrink: 0;
  }
}
</style>
